import animateScrollTo from 'animated-scroll-to';

export default class Hero {

  constructor(selector) {
    const scrollButton = document.querySelector('.js--hero .hero-scroll-button');
    const scrollTarget = document.querySelector('.js--hero .hero-scroll-target');
    const header = document.getElementById('header');
    if (!scrollButton) { return }

    scrollButton.addEventListener('click', (event) => {
      const _options = {
        minDuration: 500,
        maxDuration: 3000,
        verticalOffset: -1 * header.clientHeight,
      };
      animateScrollTo(scrollTarget, _options);
    });
  }

}

// import { scrollend } from 'scrollyfills';

export default class Header {

  constructor() {
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');
    this.header = document.querySelector('.header');
    // this.headerLogo = document.querySelector('.header-logo');
    // this.heroBottom = document.querySelector('.hero-bottom');
    // this.navigationScrollLinks = document.querySelectorAll('.navigation-scroll-link');
    this.threshold = 40;
    // this.lastScrollTop = this.html.scrollTop;

    window.addEventListener('scroll', (event) => {
      this.scrollHandler(event);
    });
    this.scrollHandler();

    // this.scrollTriggeredByNavigation = false;
    // this.navigationScrollLinks.forEach((link) => {
    //   link.addEventListener('click', (event) => {
    //     this.scrollTriggeredByNavigation = true;
    //   });
    // });
    // window.addEventListener('scrollend', (event) => {
    //   this.scrollTriggeredByNavigation = false;
    // });

    window.addEventListener('resize', (event) => {
      this.resizeHandler();
    });
    this.resizeHandler()
  }

  scrollHandler(event) {
    this.body.classList.toggle('has-scrolled', this.html.scrollTop > this.threshold);
    // this.headerLogo.classList.toggle('-translate-y-[200%]', this.html.scrollTop > this.threshold);

    // // scrolling up
    // if (this.html.scrollTop > this.threshold
    //   && this.lastScrollTop < this.html.scrollTop
    // ) {
    //   this.headerLogo.classList.add('-translate-y-[200%]');
    // }

    // // scrolling down
    // if (this.lastScrollTop > this.html.scrollTop && !this.scrollTriggeredByNavigation) {
    //   this.headerLogo.classList.remove('-translate-y-[200%]');
    // }
    // if (this.lastScrollTop > this.html.scrollTop && this.scrollTriggeredByNavigation) {
    //   this.headerLogo.classList.add('-translate-y-[200%]');
    // }

    // // save scroll position
    // this.lastScrollTop = this.html.scrollTop
  }

  resizeHandler() {
    if (this.header) {
      this.html.style.setProperty('--header-height', this.header.clientHeight + 'px');
    }
    else {
      this.html.style.setProperty('--header-height', '0px');
    }
  }

}





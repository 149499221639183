import barba from '@barba/core';
import { gsap } from "gsap/all";
import animateScrollTo from 'animated-scroll-to';
// import once from "@drupal/once";
// import Cookies from 'js-cookie';

import Header from "./header";
import NavMobile from "./nav-mobile";
import Reveal from "./reveal";
import Hero from "./hero";
import Jumpmenu from "./jumpmenu";

export default class BarbaJs {

  constructor() {
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    // const headerLogo = document.querySelector('.header-logo');
    // const navigationTrigger = document.querySelector('.navigation--trigger');
    const navigationLinks = document.querySelectorAll('.navigation--header-link');
    const languageSwitcher = document.querySelector('.language-switcher');

    barba.init({
      timeout: 5000,

      // debug: true,
      // cacheIgnore: true,
      // prefetchIgnore: true,

      // requestError: (trigger, action, url, response) => {
      //   console.log({
      //     trigger: trigger,
      //     action: action,
      //     url: url,
      //     response: response,
      //   })
      // },

      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba') || el.classList.contains('active')),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            // scroll to anchor
            if (window.location.hash) {
              const target = document.querySelector(window.location.hash);
              if (target) {
                setTimeout(() => {
                  const _options = {
                    minDuration: 500,
                    maxDuration: 3000,
                    verticalOffset: 0
                  };
                  animateScrollTo(target, _options).then((hasScrolledToPosition) => {
                    // remove hash
                    history.pushState("", document.title, window.location.pathname + window.location.search);
                  });
                }, 250);
              }
            }

            setTimeout(() => {
              new Header();
              new NavMobile();
              new Reveal('.js--reveal');
              new Hero('.js--hero');
              new Jumpmenu();
            }, 1);

            return gsap.to(body, {
              opacity: 1,
              duration: 1,
              delay: 0.25,
            });
          },

          beforeLeave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 0.5,
              // delay: data.trigger.classList != undefined && data.trigger.classList.contains('navigation--header-link') ? 1 : 0.4,
              delay: data.trigger.classList != undefined && data.trigger.classList.contains('navigation--header-link') ? 1 : 0.4,
            });
          },

          afterLeave(data) {
            // update body classes
            body.classList.toggle('is-home', data.next.namespace === 'home');
            body.classList.toggle('not-home', data.next.namespace !== 'home');
            data.current.container.remove();
          },

          beforeEnter(data) {
            html.lang = data.next.container.dataset.langCode;
            languageSwitcher.href = data.next.container.dataset.translationUrl;
            languageSwitcher.hreflang = data.next.container.dataset.translationCode;
            languageSwitcher.textContent = data.next.container.dataset.translationCode;

            navigationLinks.forEach(navigationLink => {
              navigationLink.classList.toggle('text-theme-red', navigationLink.dataset.slug === data.next.namespace || navigationLink.dataset.slug === data.next.container.dataset.slug);
            });
            data.next.container.style.opacity = 0;
          },


          enter(data) {
            // reinit JS
            setTimeout(() => {
              // new Header();
              new NavMobile();
              new Reveal('.js--reveal');
              new Hero('.js--hero');
            }, 1);

          },

          afterEnter(data) {
            window.scrollTo(0, 0);

            return gsap.to(data.next.container, {
              opacity: 1,
              duration: 1,
              delay: 0.5,
              // onStart: () => {
              // },
              onComplete: () => {
                // scroll to anchor
                if (window.location.hash) {
                  const target = document.querySelector(window.location.hash);
                  if (target) {
                    setTimeout(() => {
                      const _options = {
                        minDuration: 500,
                        maxDuration: 3000,
                        verticalOffset: 0
                      };
                      animateScrollTo(target, _options).then((hasScrolledToPosition) => {
                        // remove hash
                        history.pushState("", document.title, window.location.pathname + window.location.search);
                      });
                    }, 250);
                  }
                }
              }
            });
          }

        },
      ],

    });
  }

}

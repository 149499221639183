export default class Member extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const flipContainer = this.querySelector('.member-flip-container');
    if (flipContainer) {
      flipContainer.addEventListener('touchend', (event) => {
        if (!this.classList.contains('touched')) {
          const otherTouchedMembers = document.querySelectorAll('.member-flip.touched')
          const otherTouchedMembersList = [...otherTouchedMembers].map((otherTouchedMember) => {
            otherTouchedMember.classList.remove('touched');
          });
        }
        this.classList.toggle('touched');
      });
    }
  }

}

// Define the new web component
if ('customElements' in window) {
  customElements.define('lhpk-member', Member);
}

import animateScrollTo from 'animated-scroll-to';

export default class Jumpmenu {

  constructor(selector) {
    const navigationLinks = document.querySelectorAll('.navigation-scroll-link');

    const navigationLinksArray = [...navigationLinks].map((link) => {
      link.addEventListener('click', (event) => {
        const target = document.querySelector(link.hash);
        if (target) {
          event.preventDefault();
          const _options = {
            minDuration: 500,
            maxDuration: 3000,
          };
          animateScrollTo(target, _options);
        }
      });
    });
  }

}
